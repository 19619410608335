<template>
<!-- 同步大班课 -->
  <div class="addclass">
    <div class="content">
      <div class="cont-mid">
        <el-form ref="form" :rules="rules" :model="form" label-width="190px">
          <el-form-item label="课节名称：" prop="lessonsName">
            <el-input
              v-model="form.lessonsName"
              maxlength="100"
              show-word-limit
              style="width:555px"
            ></el-input>
          </el-form-item>

          <!-- 大班普通课 -->
          <el-form-item label="上课时间：" required>
            <dateTimePikcer @dateTimePicker="handleTimeChange"></dateTimePikcer>
            <el-tooltip class="item" effect="dark" content="请选择当前时刻起，一小时后的时间点" placement="top" v-if="$route.query.courseType == 3" >
              <i class="el-icon-question csp f20" ></i>
            </el-tooltip>
          </el-form-item>

          <el-row>
            <el-col :span="13">
              <el-form-item label="授课老师：" :required="true" >
                <teacherSelect :requestType="'teacher'" :courseId="Number($route.params.id)" @teacherSelect="handleTeacherChange" />
              </el-form-item>
            </el-col>
            <el-col :span="11">
               <teacherSelect :requestType="'assistant'" :courseId="Number($route.params.id)" @teacherSelect="handleTeacherChange" />
            </el-col>
          </el-row>

          <el-form-item label="PC端直播面板：" prop="isVideoMain" :required="true">
            <el-radio-group v-model="form.isVideoMain">
              <el-radio :label="1">以视频为主</el-radio>
              <el-radio :label="2">以PPT为主</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="手机端直播面板：" prop="mobileIsVideoMain" :required="true">
            <el-radio-group v-model="form.mobileIsVideoMain">
              <el-radio :label="1">以视频为主</el-radio>
              <el-radio :label="2">以PPT为主</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="直播课件："  :required="$route.query.courseType == 2" > 
            <uploadFile @uploadFile="handleFileChange" :name="'liveIdentifiers'" :sourceType=2 />
          </el-form-item>

          <el-form-item label="课节附件：">
            <uploadFile @uploadFile="handleFileChange" :name="'lessonIdentifiers'" :sourceType=2 />
          </el-form-item>

          <el-form-item label="点播视频：" :required="true" v-if="$route.query.courseType == 3">
            <uploadFile @uploadFile="handleFileChange" :name="'vodIdentifier'" :sourceType=1 />
          </el-form-item>

          <el-form-item label="回放视频：">
            <uploadFile @uploadFile="handleFileChange" :name="'videoIdentifiers'" :tips="{image:false,url:true}" :sourceType=1 />
          </el-form-item>

          <el-form-item class="el-form-item-btns">
            <el-button @click="$router.push(`/lesson/${$route.params.id}?breadNumber=4`)">取消</el-button>
            <el-button type="primary" @click="onSubmit">确定</el-button>
          </el-form-item>

          <br />
          <br />
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "newcourse",
  data() {
    return {
      form: {
        isVideoMain:2,
        mobileIsVideoMain:1,
      },
      rules: {
        lessonsName: [{ required: true, message: "请输入课节名称", trigger: "blur" }],
        video: [{ required: true }],
        isVideoMain: [{ required: true, message: "请选择PC端面板", trigger: "change" }],
        mobileIsVideoMain: [{ required: true, message: "请选择手机端面板", trigger: "change" }],
      },
    };
  },
  computed: {},
  components: {},
  methods: {
    handleTimeChange(data){
      // console.log(data);
      this.form.lessonsBeginTime = data.bTime
      this.form.lessonsEndTime = data.eTime
      // console.log(this.form);
    },
    handleTeacherChange(data){
      // console.log(data);
      let type = data.type === 'teacher' ? true : (data.type === 'assistant'? false: '')
      if(type) {
        this.form.teacherId = data.teacherId
        this.form.teacherRole = data.role
      } else {
        this.form.assistantId = data.teacherId
        this.form.assistantRole = data.role
      }
      // console.log(this.form);
    },
    handleFileChange(data){
      // console.log(data);
      this.form[data.name] = data.fileMD5List;
      // console.log(this.form)
    },
    onSubmit() {
      this.$refs['form'].validate((valid)=>{
        if(valid) {
          if(this.$route.query.courseType ==3 &&(!this.form.vodIdentifier || this.form.vodIdentifier.length == 0)) {
            this.$message({
              showClose: true,
              message: '未添加点播视频！',
              type: 'error'
            });
            return;
          }

          if(this.form.lessonsName.trim() == '') {
            this.$message.error('课程名称不能为空');
            return;
          }
          this.commitForm();
        }
      })
    },
    async commitForm(){
      this.form.courseProjectId = this.$route.params.id;
      if(typeof(this.form.vodIdentifier) !== 'string')  {
        this.form.vodIdentifier = this.form.vodIdentifier ? this.form.vodIdentifier[0] : '';
      }
      // console.log(this.form);
      const loading = this.$loading(this.$store.state.user.loadingConfig);
      let resData = await this.$Api.Course.creatLesson(this.form);
      if (resData.code === 200) {
        loading.close();
        this.$notify({
          title: "成功",
          message: "创建成功",
          type: "success"
        });
        setTimeout(() => {
          this.$router.push(`/lesson/${this.$route.params.id}?breadNumber=4`);
        }, 500);
      } else {
        loading.close();
        this.$notify.error({
          title: "错误",
          message: resData.msg
        });
      }
    }
  },
  deactivated() {
    this.form = {
      isVideoMain:2,
      mobileIsVideoMain:1,
    }
    this.$refs["form"].resetFields();
  },
  created() {}
};
</script>

<style lang="less"></style>
